import React from "react"
import NavigationBar from "../components/Navbar"
import { Helmet } from "react-helmet"
import loadable from "@loadable/component"
import SocialMedia from "../components/social-media"

const NewsletterStrip = loadable(() => import("../components/newsletterStrip"))
const CoursesAndBlogs = loadable(() => import("../components/CoursesAndBlogs"))
const Footer3 = loadable(() => import("../components/footer3"))
const FaqSection = loadable(() => import("../components/newFAQ"))

export default class ContactFormPage extends React.Component {
  state = {
    selectedOption: null,
  }
  handleChange = selectedOption => {
    this.setState({ selectedOption }, () =>
      console.log(`Option selected:`, this.state.selectedOption)
    )
  }
  render() {
    return (
      <div>
        <Helmet
          htmlAttributes={{
            lang: "en",
          }}
        >
          <meta charSet="utf-8" />
          <title>Contact Us</title>
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1.0"
          />
          <meta
            name="description"
            content="Connect with us to get computer science learning and interview guidance."
          />
        </Helmet>
        <div className="sm:sticky top-0 z-50">
          <NavigationBar />
        </div>

        <div className="bg-white">
          <div className="container mx-auto px-4 md:px-6 lg:px-6 xl:px-8 2xl:px-10 pt-6 lg:pt-8">
            <div className="lg:grid lg:grid-cols-5">
              <div className="lg:col-span-2">
                <div className="max-w-lg mx-auto">
                  <h1 className="tracking-wider text-xl lg:text-2xl xl:text-3xl text-gray-900 leading-8">
                    Get in Touch
                  </h1>
                  <ul className="mt-1 sm:mt-5 tracking-wider leading-6 text-left md:text-left text-gray-600 list-outside">
                    Drop us a line or give us a heads up if you are interested
                    in visiting us. We would love to connect regarding the
                    following:
                    <li className="mt-4 ml-5 list-disc">
                      Sharing content feedback
                    </li>
                    <li className="ml-5 list-disc">Tech interview guidance</li>
                    <li className=" ml-5 list-disc">
                      Joining our live courses
                    </li>
                    <li className=" ml-5 list-disc">
                      Becoming part of our team
                    </li>
                  </ul>

                  <div className="text-gray-600 tracking-wider">
                    <p className="mb-2 mt-4">
                      Email: contact@enjoyalgorithms.com
                    </p>

                    <p className="mb-2">Contact: +91-8287623528.</p>
                    <p className="mb-4">
                      Business address: N-161, Saira Tower, G.F, Gautam Nagar,
                      Near Green Park Metro Station Gate no. 2, New Delhi, South
                      Delhi DL-110049 IN.
                    </p>
                  </div>
                </div>
              </div>
              <div className="px-4 pb-2 mt-8 md:mt-4 sm:px-6 lg:col-span-3 lg:px-8">
                <div className="max-w-lg mx-auto lg:max-w-none">
                  <form
                    name="contact"
                    method="POST"
                    action="/thank-you/"
                    netlify-honeypot="bot-field"
                    data-netlify="true"
                    className="grid grid-cols-1"
                  >
                    <p className="hidden">
                      <label>
                        Don’t fill this out if you’re human:
                        <input name="bot-field" />
                      </label>
                    </p>
                    <input type="hidden" name="form-name" value="contact" />
                    <div className="my-2">
                      <label htmlFor="full_name" className="sr-only">
                        Full Name
                      </label>
                      <div className="border border-gray-300 rounded">
                        <input
                          name="fullname"
                          id="full_name"
                          className="block w-full tracking-wider px-4 py-3 text-sm placeholder-gray-600 transition duration-150 ease-in-out form-input"
                          placeholder="Name"
                          onFocus={e => (e.target.placeholder = "")}
                          onBlur={e => (e.target.placeholder = "Name")}
                          required
                        />
                      </div>
                    </div>
                    <div className="my-2">
                      <label htmlFor="email" className="sr-only">
                        Email
                      </label>
                      <div className="border border-gray-300 rounded">
                        <input
                          name="email"
                          id="email"
                          type="email"
                          className="block w-full tracking-wider px-4 py-3 text-sm placeholder-gray-600 transition duration-150 ease-in-out form-input"
                          placeholder="Email"
                          onFocus={e => (e.target.placeholder = "")}
                          onBlur={e => (e.target.placeholder = "Email")}
                          required
                        />
                      </div>
                    </div>

                    <div className="my-2">
                      <label htmlFor="message" className="sr-only">
                        Message
                      </label>
                      <div className="border border-gray-300 rounded">
                        <textarea
                          name="message"
                          id="message"
                          rows={4}
                          className="block w-full tracking-wider px-4 py-3 text-sm placeholder-gray-600 transition duration-150 ease-in-out form-input"
                          placeholder="Message"
                          onFocus={e => (e.target.placeholder = "")}
                          onBlur={e => (e.target.placeholder = "Message")}
                          required
                        />
                      </div>
                    </div>

                    <div className="my-4 flex justify-center">
                      <span className="inline-flex rounded">
                        <button
                          type="submit"
                          id="sendMessageutton"
                          className="px-5 py-3 shadow tracking-wider text-base xl:text-lg rounded border border-new-purple hover:bg-new-purple text-new-purple hover:text-white transition duration-150"
                        >
                          Send Message
                        </button>
                      </span>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <SocialMedia />
            <div className="p-4 sm:p-6">
              <FaqSection />
            </div>
            <div className="mt-6">
              <NewsletterStrip />
            </div>

            <div className="mt-6">
              <CoursesAndBlogs />
            </div>
            <Footer3 />
          </div>
        </div>
      </div>
    )
  }
}
